.App {
  text-align: center;
  background-color: #0c131a;

  /* background-color: #14242c; */
  /* background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%); */
  background-blend-mode: screen, overlay;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* overflow-x: hidden; */
}
footer {
  margin-top: auto;
}
/* .li > div > div {
  background-color: #0c131a;
} */

/* #footer {
  padding: 15px 5px;
  background-color: pink;
  margin-top: auto;
}

#header {
  position: sticky;
  top: 0;
  padding: 15px 5px;
  background-color: pink
} */
